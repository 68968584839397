// 1 = loading in progress
// 0 = loading is done, there can be more items
// -1 = loading is done, there shouldn't be more items
enum LOADING {
  IN_PROGRESS = 1,
  PARTIAL_DONE = 0,
  DONE = -1
}

export default LOADING;
