
































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import BaseButton from '@improve/common-components/src/components/widgets/BaseButton.vue';
import BaseModal from '@improve/common-components/src/components/widgets/BaseModal.vue';
import BaseTextArea from '@improve/common-components/src/components/core/BaseTextArea.vue';

@Component({
  name: 'WontDoReasonInput',
  components: {
    BaseModal,
    BaseButton,
    BaseTextArea
  }
})
export default class WontDoReasonInput extends Vue {
  @Prop({ default: null }) readonly reason!: string;

  @Prop({ default: false }) readonly show!: boolean;

  @Prop({ default: false }) readonly inProgress!: boolean;

  reasonText = '';

  created(): void {
    this.reasonText = this.reason;
  }

  async confirm(): Promise<void> {
    if (!(await this.validate())) {
      return;
    }
    this.$emit('confirm', this.reasonText);
  }

  validate(): Promise<any> {
    const component: any = this.$refs.obs;
    return component.validate();
  }
}
