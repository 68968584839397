





















































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';
import draggable from 'vuedraggable';
import BaseSelect from '@improve/common-components/src/components/core/BaseSelect.vue';
import BaseTicketSkeletonCard
  from '@improve/common-components/src/components/widgets/BaseTicketSkeletonCard.vue';
import DragEvent from '@improve/common-utils/src/model/DragEvent';
import Ticket from '@improve/common-utils/src/model/Ticket';
import { MenuOption } from '@improve/common-utils/src/types/MenuOption';
import User from '@improve/common-utils/src/model/User';
import ImproveTicketStatus from '@improve/common-utils/src/types/ImproveTicketStatus';
import TicketSearchParams from '@improve/common-utils/src/types/TicketSearchParams';
import WontDoReasonInput from '@/components/widgets/WontDoReasonInput.vue';
import LOADING from '@improve/common-utils/src/types/Loading';
import Topic from '@improve/common-utils/src/model/Topic';
import Team from '@improve/common-utils/src/model/Team';
import BaseChips from '@improve/common-components/src/components/widgets/BaseChips.vue';
import BaseTicketCard from '../../ticket/BaseTicketCard.vue';

type DelegateOptionType = {
  id?: string;
  name?: string;
  tagName?: string;
  header?: string;
}

@Component({
  name: 'AssignmentsTab',
  components: {
    draggable,
    BaseSelect,
    BaseChips,
    BaseTicketCard,
    BaseTicketSkeletonCard,
    WontDoReasonInput
  }
})

export default class AssignmentsTab extends Vue {
  @Getter currentUser?: User;

  @Getter activeWorkflowStatuses!: Array<string>;

  @Getter assignedTickets!: Map<string, Array<Ticket>>;

  @Getter getAssignedTicketsByStatus!: (st: string) => Array<Ticket>;

  @Getter organizationTopics!: Array<Topic>;

  @Getter teams!: Array<Team>;

  @Action fetchTransitions!: ActionMethod;

  @Action getAssignedTickets!: ActionMethod;

  @Action updateTicket!: ActionMethod;

  @Action updateTicketStatus!: ActionMethod;

  @Action validateStatusTransition!: ActionMethod;

  @Action setAlertMessage!: ActionMethod;

  showSuccessMessage = false;

  menuOptions: Array<MenuOption> = [];

  searchStatuses: Map<string, TicketSearchParams> = new Map<string, TicketSearchParams>();

  loadingByStatus: Map<string, number> = new Map<string, number>();

  selectedTicketForWontDo: Ticket | null = null;

  statusChangeInProgress = false;

  // Any, because with a Map there are too many null checks in usage in this case
  moveEvents: any = {};

  selectedFilter: Array<DelegateOptionType> = [];

  filterOptions: Array<DelegateOptionType> = [];

  get chipFilteredOptions(): Array<MenuOption> {
    return this.selectedFilter.map((option: any) => ({
      title: option.name || option.tagName,
      value: option.id
    }));
  }

  get activeColumns(): Array<string> {
    return this.activeWorkflowStatuses
      ? this.activeWorkflowStatuses.filter((_) => _ !== ImproveTicketStatus.DRAFT)
      : [];
  }

  async fetchAllAssignedTickets(selection?: Array<string>): Promise<void> {
    await Promise.all([
      this.fetchAssignedTickets(ImproveTicketStatus.READY_TO_REVIEW, selection),
      this.fetchAssignedTickets(ImproveTicketStatus.IN_REVIEW, selection),
      this.fetchAssignedTickets(ImproveTicketStatus.WAITING_FOR_IMPLEMENTATION, selection)
    ]);
  }

  async created(): Promise<void> {
    await this.fetchTransitions();
    await this.fetchAllAssignedTickets();

    await this.fetchFilterData();
    await this.parseFilterQuery();
  }

  async parseFilterQuery(): Promise<void> {
    const queryOptions = this.$route.query.selectedOptions as string || '';
    const ids = queryOptions
      .split(',')
      .reduce((prevValue: any, newValue: any) => ({
        ...prevValue,
        [newValue]: newValue
      }), {});

    this.filterOptions.forEach((option: any) => {
      if (Object.prototype.hasOwnProperty.call(ids, option.id)) {
        this.selectedFilter.push(option);
      }
    });
  }

  async fetchFilterData(): Promise<void> {
    // sort teams
    this.sortFilterData(this.teams);

    if (this.teams.length) {
      this.filterOptions.push({ header: 'Teams' });
    }

    this.teams.forEach((team) => {
      this.filterOptions.push({
        id: `TEAM:${team.id}`,
        name: team.name
      });
    });

    // sort topics
    this.sortFilterData(this.organizationTopics);

    if (this.organizationTopics.length) {
      this.filterOptions.push({ header: 'Topics' });
      this.organizationTopics.forEach((topic) => {
        this.filterOptions.push({
          id: `TOPIC:${topic.id}`,
          name: topic.metaData.displayName!
        });
      });
    }
  }

  sortFilterData(options: Array<Topic | Team>): void {
    options.sort((a: any, b: any): 1 | -1 | 0 => {
      const optionA = a?.name?.toUpperCase();
      const optionB = b?.name?.toUpperCase();

      if (optionA < optionB) {
        return -1;
      }
      if (optionA > optionB) {
        return 1;
      }
      return 0;
    });
  }

  async applyFilter(delegate: Array<DelegateOptionType>): Promise<void> {
    const selectedOptions = delegate.map((option: DelegateOptionType) => option.id!);
    this.updateRoute(selectedOptions);
    await this.fetchAllAssignedTickets(selectedOptions);
  }

  removeOption(id: string): void {
    this.selectedFilter = this.selectedFilter.filter((option: any) => option.id !== id);
    this.applyFilter(this.selectedFilter);
  }

  clearAllOptions(): void {
    this.selectedFilter = [];
    this.applyFilter(this.selectedFilter);
  }

  updateRoute(ids: Array<string>): void {
    this.$router.replace({
      query: {
        selectedOptions: ids.join(',')
      }
    });
  }

  async fetchAssignedTickets(status: string, delegate?: Array<string>): Promise<void> {
    const searchBy = new TicketSearchParams();
    searchBy.page = 0;
    searchBy.size = 20;
    searchBy.status = [status];

    if (delegate && delegate.length > 0) {
      searchBy.delegate = delegate;
    }
    await this.getTickets(status, searchBy);
  }

  async getTickets(status: string, params: TicketSearchParams): Promise<void> {
    // 1 = loading in progress
    // 0 = loading is done, there can be more items
    // -1 = loading is done, there shouldn't be more items
    this.searchStatuses.set(status, params);
    this.loadingByStatus.set(status, LOADING.IN_PROGRESS);
    this.$forceUpdate();
    const tickets = await this.getAssignedTickets(params);
    const loadingState = tickets.length < params.size ? LOADING.DONE : LOADING.PARTIAL_DONE;
    this.loadingByStatus.set(status, loadingState);
    this.$forceUpdate();
  }

  paginationInProgress(status: string): boolean {
    return this.loadingByStatus.get(status) === LOADING.IN_PROGRESS;
  }

  areTicketsAvailable(status: string): boolean {
    return !!this.getAssignedTicketsByStatus(status);
  }

  ticketsByStatus(status: string): Array<Ticket> {
    return this.getAssignedTicketsByStatus(status);
  }

  async setScrollEvents(e: UIEvent, status: string): Promise<void> {
    const t = e.target as HTMLElement;
    const percentage = Math.floor(((t.clientHeight + t.scrollTop) / t.scrollHeight) * 100);
    if (percentage > 75 && this.loadingByStatus.get(status)! === LOADING.PARTIAL_DONE) {
      const nextParams = this.searchStatuses.get(status)!.nextPage();
      await this.getTickets(status, nextParams);
    }
  }

  async updateTickets(e: Record<string, string>, status: string): Promise<void> {
    Object.keys(e).forEach((_: string) => {
      this.moveEvents[_] = e[_];
      this.moveEvents[_].status = status;
    });
    if (this.moveEvents.added
      && this.moveEvents.removed
      && this.moveEvents.added.element === this.moveEvents.removed.element) {
      const isValid = await this.validateStatusTransition({
        ticket: this.moveEvents.added.element,
        nextStatus: this.moveEvents.added.status
      });

      if (!isValid.result) {
        this.setAlertMessage({
          message: this.getErrorMessage(
            isValid,
            this.$t(`ticket.status.${this.moveEvents.removed.status}`).toString(),
            this.$t(`ticket.status.${this.moveEvents.added.status}`).toString()
          ),
          show: true
        });
      } else {
        await this.updateTicketStatus({
          ticket: this.moveEvents.added.element,
          fromStatus: this.moveEvents.removed.status,
          toStatus: this.moveEvents.added.status,
          newIndex: this.moveEvents.added.newIndex
        });
        this.setAlertMessage({
          message: `${this.$t('page.userProfile.assignmentsTab.improveMovedTo')}
          ${this.$t('ticket.status.'.concat(this.moveEvents.added.status))}
          ${this.$t('page.userProfile.assignmentsTab.phase')}`,
          show: true
        });
      }
      this.moveEvents = {};
    }
    this.$forceUpdate();
  }

  async setTicketToWontDo(reason: string): Promise<void> {
    if (!this.selectedTicketForWontDo) return;
    // Set the reason
    this.selectedTicketForWontDo.meta.wontDoReason = reason;

    // Update the ticket itself
    await this.updateTicket(this.selectedTicketForWontDo);

    // Update the ticket status
    await this.updateTicketStatus({
      ticket: this.selectedTicketForWontDo,
      fromStatus: this.selectedTicketForWontDo?.status,
      toStatus: ImproveTicketStatus.WONT_DO
    });
    // Hide the confirmation modal
    this.selectedTicketForWontDo = null;
    this.setAlertMessage({
      message: this.$t('page.wontDoTicket.wontDoConfirmation'),
      show: true
    });
    this.$forceUpdate();
  }

  getErrorMessage(
    validation: { result: boolean; reason?: string; args?: Array<string> },
    fromStatus: string,
    toStatus: string
  ): string {
    switch (validation.reason) {
      case 'invalid.transition': {
        return this.$t('error.invalidTransition', { fromStatus, toStatus }).toString();
      }
      case 'invalid.fields': {
        const fields = validation.args?.join(', ') || '';
        return this.$t('error.invalidFields', { fields }).toString();
      }
      default:
        return '';
    }
  }

  async onMenuSelect(ticket: Ticket, option: MenuOption): Promise<void> {
    switch (option.value) {
      case 'assignToMe': {
        // eslint-disable-next-line no-param-reassign
        ticket.assignee = this.currentUser!.id!;
        await this.updateTicket(ticket);
        this.$forceUpdate();
        break;
      }
      case 'assignToSomeone': {
        this.gotoTicketDetails(ticket);
        break;
      }
      case 'wontDo': {
        // Ask for confirmation and reason
        this.selectedTicketForWontDo = ticket;
        break;
      }
      default: {
        // because eslint doesnt like empty block
        break;
      }
    }
  }

  setMenuOptions(ticket: Ticket): Array<MenuOption> {
    const options = [];
    if (this.currentUser?.id !== ticket.assignee) {
      options.push({
        title: this.$t('menu.assignToMe').toString(),
        value: 'assignToMe'
      });
    }
    options.push({
      title: this.$t('menu.assignToSomeone').toString(),
      value: 'assignToSomeone'
    });
    if (ticket.status !== ImproveTicketStatus.WONT_DO) {
      options.push({
        title: this.$t('menu.wontDo').toString(),
        value: 'wontDo'
      });
    }
    return options;
  }

  gotoTicketDetails(ticket: Ticket): void {
    this.$router.push({
      name: 'TicketDetails',
      params: { id: ticket.canonicalId! }
    });
  }

  addHoverEffect($event: DragEvent): void {
    // Only do it for the implemented column
    if ($event?.to?.classList.contains('implemented-column')) {
      $event?.to?.classList.add('implemented-column-hover');
    } else {
      this.removeHoverEffect();
    }
  }

  removeHoverEffect(): void {
    const el = document.getElementsByClassName('implemented-column-hover');
    el[0]?.classList.remove('implemented-column-hover');
  }
}
